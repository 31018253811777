
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-email-subscribe {
        $this: &;

        padding-top: $nu-spacer-6;
        padding-bottom: $nu-spacer-4;
        text-align: center;
        background-color: $nu-secondary;

        @include breakpoint(large) {
            padding-top: $nu-spacer-5;
            padding-bottom: $nu-spacer-1pt5;
        }

        &--light {
            background-color: $nu-white;
        }

        &:not(&--modal) {
            @include breakpoint(large) {
                text-align: left;
            }
        }

        &--modal {
            text-align: center;
            padding: $nu-spacer-6 0 $nu-spacer-8;

            @include breakpoint(large) {
                padding: $nu-spacer-8 0 $nu-spacer-12;
            }
        }

        &--error {
            #{$this}__content-container {
                @include breakpoint(large) {
                    align-items: flex-end;
                }
            }
        }

        &__form-container {
            &:not(&--modal) {
                @include breakpoint(large) {
                    grid-column-start: auto;
                }
            }
        }

        &__success-screen {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            opacity: 0;
            transition: opacity .5s ease;
            margin-bottom: $nu-spacer-4;
            z-index: -1;

            &--show {
                opacity: 1;
                z-index: 0;
            }

            @include breakpoint(large) {
                margin-bottom: 0;
            }
        }

        &__success-screen-buttons {
            display: flex;
            justify-content: center;
        }

        &__success-screen-button {
            margin-right: $nu-spacer-3;
        }

        &__illustration {
            display: inline-block;
            vertical-align: middle;
            height: 48px;
            margin-bottom: $nu-spacer-1;

            @include breakpoint(large) {
                margin-bottom: 0;
                margin-right: $nu-spacer-2;
            }

            &--modal {
                height: auto;
                margin-bottom: $nu-spacer-3;
                margin-right: 0;
            }
        }

        &__content-container {
            margin-bottom: $nu-spacer-3;

            @include breakpoint(large) {
                display: flex;
                margin-bottom: 0;
            }

            &--modal {
                display: block;
            }
        }

        &__form {
            &:not(&--modal) {
                form {
                     @include breakpoint(medium) {
                        margin-bottom: $nu-spacer-2;
                        display: flex;
                    }

                    @include breakpoint(large) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &__input {
            height: 48px;
            margin-bottom: $nu-spacer-4;
            padding-top: $nu-spacer-1pt5;

            @include breakpoint(medium) {
                margin-bottom: 0;
                padding-right: $nu-spacer-3;
            }
        }

        &__submit-button {
            width: 100%;

            @include breakpoint(medium) {
                width: 202px;
            }
        }
    }
