
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-error-banner {
        border-radius: 2px;
        display: flex;
        align-items: stretch;
        flex-wrap: nowrap;

        &--white {
            background-color: $nu-white;
        }

        &--lightgray {
            background-color: $nu-gray--light;
        }

        &__icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            background-color: $nu-primary;
            border-radius: 2px 0 0 2px;
            width: 32px;
        }

        &__text-container {
            text-align: left;
            padding: $nu-spacer-1 $nu-spacer-1pt5;
        }
    }
