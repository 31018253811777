
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-markdown {
        word-break: break-word;

        p {
            &:not(:last-child) {
                margin-bottom: $nu-spacer-1pt5;
            }
        }

        ul,
        ol {
            margin-left: $nu-spacer-3;
        }

        ul {
            list-style-type: disc;
            margin-left: $nu-spacer-3;

            li {
                @include caption;
                margin-bottom: $nu-spacer-1pt5;
            }
        }

        ol {
            list-style-type: decimal;
        }
    }
